<template>
  <div>
    <div class="main">
      <van-swipe :autoplay="3000" class="swipe-box">
        <van-swipe-item>
          <img src="../../assets/images/banner.jpg" />
        </van-swipe-item>
      </van-swipe>
      <section class="text-box">
        <p class="home-title">武汉市文物监管品市场</p>
        <p class="text-2">武汉市文物监管品市场于1997年6月8日成立，是经湖北省文化厅、武汉市人民政府批准的武汉市合法的古玩市场。随着我国经济的繁荣，文化事业的发展，中国文物艺术品的价值越来越高，文物艺术品市场进一步激活，民间收藏比以往任何时代都具有更大的群众性，为了加强文物市场的流通和管理，继承我国优秀的历史文化遗产，发展古文化、楚文化，促进武汉市两个文明建设的发展。使其成为研究和发展古文化、楚文化以及现代工艺美术品的基地，成为武汉市对外开放，发展旅游事业的一个景点。</p>
      </section>
      <section style="margin-bottom:10px;">
        <div class="flex img-box">
          <img src="../../assets/images/home1.jpg" @click="openPic(require('../../assets/images/home1.jpg'))">
          <img src="../../assets/images/home2.jpg" @click="openPic(require('../../assets/images/home2.jpg'))">
          <van-image-preview v-model="show" :images="images"></van-image-preview>
        </div>
      </section>
      <p style="font-size:12px;text-align: center;">版权️所有：武汉市文物监管品市场</p>
      <p style="font-size:12px;text-align: center;">鄂ICP备2021006869号 <a href="http://www.i-bf.com"> 技术支持：闯荡动力</a></p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      images: []
    }
  },
  methods: {
    openPic (pic) {
      this.images = []
      this.images.push(pic)
      this.show = true
    }
  }
}
</script>

<style lang="less" scoped>
.main{
  padding-bottom: 2rem;
}
.swipe-box{
  width: 100vw;
  height: 4rem;
  img{
    width: 100%;
    height: 100%;
  }
}
.text-box{
  padding: 10px;
}
.text-2{
  font-size: 18px;
  line-height: 1.2;
  text-align:justify;
  text-justify:distribute-all-lines;
  text-indent:2em;
  margin-top: 5px;
}
.home-title{
  font-size: 26px;
  text-align: center;
  color: #0F3456;
  font-weight: bold;
}
.img-box{
  width: 100vw;
  img {
    width: 50%;
    height: 150px;
  }
}
</style>
